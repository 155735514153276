<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <div
      :class="classes"
      :disabled="disabled"
      :data-node-id="nodeId"
    />
  </td>
</template>

<script>
// Noch nicht implementiert

export default {
  name: 'ChecklistCellTypeTree',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.background_color ||
        this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return `tree-singleselect${this.state.classes}`
    },
    disabled() {
      return this.state.disabled
    },
    nodeId() {
      const settings = this.cell.settings
      if (settings.tree_source) {
        return settings.tree_source
      }
      if (settings.tree_source_unlock) {
        return settings.tree_source_unlock
      }
      return false
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      let value = builder.getCellValue(index, this.cell, this.clone)
      try {
        if (typeof value === 'string') {
          value = JSON.parse(value)
        }
      } catch (ex) {
        value = []
      }
      value = parseInt(builder.getSelectPropertyToArray(value, 'id')[0])
      return value || 0
    }
  }
}
</script>

<style>
  .checklist-viewer .element-content .tree-singleselect {
    vertical-align: top;
    text-align: left;
  }
</style>
